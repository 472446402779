@import "variables";
@import 'stylesheets/decidim/utils/settings';

.multiple_votes_form_group_title {
	h5  {
		flex-shrink: 1 !important;
		label {
			font-size: inherit;
			font-weight: inherit;
			line-height: 1.5;
			color: inherit;
			margin: 0 0 -10px 0;
		}
	}
}

.multiple_votes_form {
	>div {
		display: flex;
		>input {
			align-self: flex-start;
			margin-top: 5px;
		}
	}
}

.masonry {
	$masonry-css-column-gap: $global-margin; // TODO: get it from $global-margin foundation CSS;

  column-count: 1;
  column-gap: $masonry-css-column-gap;

  @include breakpoint(map-get($breakpoints, medium)) {
    column-count: 2;
  }

  @include breakpoint(map-get($breakpoints, large)) {
    column-count: 3;
  }

	.column {
		// override float styles from foundation
		float: none !important;
		width: auto !important;
	  break-inside: avoid;
	  margin-bottom: $masonry-css-column-gap;
	}
}