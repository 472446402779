@import "variables";
@import "foundation-sites/scss/foundation";
@import "stylesheets/decidim/utils/settings";


// Overide background default bar
.title-bar{
	background-color: $title-bar-bg;
}

.navbar {
	background-color: $navbar-bg;
}
.main-nav__link a {
	color: $navbar-fg;
	&:hover {
		color: $navbar-fg-hover;
	}
}
.main-nav__link--active a {
	background: $navbar-bg-hover;
}

.show-for-medium {
	.main-nav__link a:hover {
		box-shadow: inset 0 4px 0 0 lighten($primary, 10%);
	}
}
.hide-for-medium {
	.main-nav__link a:hover {
	    box-shadow: inset 4px 0 0 0 lighten($primary, 10%);
	}
}
.off-canvas .close-button {
  color: $primary;
}

.dropdown.menu .is-active > a {
	color: $link-color;
}

.topbar_ {
	&_menu {
		padding: 0.125rem 0.5rem 0;
		background: rgba(48, 65, 93, 0.1);
		border-radius: 12.5%;
		[data-toggle] {
			margin-bottom:0;
			padding: 6px 3px;
		}
		.icon--menu {
			fill: $primary;
			width: 1em;
			height: 1em;
		}
	}
	&_edit__link a {
		padding-left: 5px;
		color: $link-color;
		&:hover {
			color: $link-color;
		}
	}
	&_user__login{
	  a {
	    font-weight: 500;
	    color: $link-color;
	  }
	}
	&_notifications, &_conversations{
	  .icon {
			fill: $link-color;
	  }
	}

	&_dropmenu {
		> ul > li > a {
			color: $link-color;
		}
		.is-dropdown-submenu-parent {
			> a, &.is-active > a {
				@include breakpoint(smallmedium down) {
					color: white;
				}
			}
		}
		.is-dropdown-submenu li a {
			color: $link-color;
		}
	}
	&_search {
		input, input:focus {
			background-color: $navbar-bg;
			color: $navbar-fg;
		}
		input::placeholder, textarea::placeholder {
			color: $navbar-fg;
		}
		svg {
			color: $navbar-fg;
		}
		&:not(:focus-within) button {
		  background: $navbar-bg;
		}
	}
}


.hide-for-medium{

  .topbar__user__login{
    border-bottom: none;
    border-top: none;

    a{
      color: $navbar-bg;
      font-weight: 600;

      &:hover{
        color: $anchor-color;
      }
    }
  }
}

.logo-wrapper span{
	color: $link-color;
}