.social-register, #loginModal {
  .button--social.button--civicrm {
    img.icon.external-icon {
      display: block !important;
      filter: brightness(0) invert(1);
      width: auto;
      height: 100%;
    }
  }
}

#loginModal {
  .button--social.button--civicrm {
    img.icon.external-icon {
      height: 2rem;
      margin: 0 0.5rem;
    }
    .button--social__icon {
      float: left;
    }
    .show-for-sr {
      display: block;
      height: auto !important;
      width: auto !important;
      position: static !important;
      clip: auto !important;
      white-space: normal !important;
      overflow: auto !important;
      margin-top: 1.3rem;
      padding-right: 0.6rem !important;
    }
  }
}

