// Foundation Variables
$primary-color: null;
$secondary-color: null;
$success-color: null;
$warning-color: null;
$alert-color: null;
$-zf-size: null;
$-zf-bp-value: null;

// Variables

// $primary: #cb3c29 !default;
$primary: #D83926 !default;
// $primary-rgb: 239,96,77 !default;
$primary-rgb: 216,57,38 !default;
// $secondary: #39747f !default;
// $secondary: #CD1889;
$secondary: #D83926;
// $secondary-rgb: 57,116,127 !default;
$secondary-rgb: 216,57,38 !default;
// $success: #57d685 !default;
// $success: #008C41;
$success: #D83926 !default;
$success-rgb: 216,57,38 !default;
// $warning: #FFDC00;
$warning: #FFAE00;
$warning-rgb: 255,174,0 !default;
// $alert: #ec5840 !default;
// $alert: #E53726;
$alert: #D83926 !default;
$alert-rgb: 216,57,38 !default;
$highlight: #be6400 !default;
$highlight-rgb: 190,100,0 !default;
$highlight-alternative: #ff5731 !default;
$highlight-alternative-rgb: 255,87,49 !default;

$proposals: #238ff7 !default;
$actions: #57d685 !default;
$debates: #fa6c96 !default;
$meetings: #fabc6c !default;

$twitter: #55acee !default;
$facebook: #3b5998 !default;
$google: #4285f4 !default;

// background colors used in public diff views
$color-addition: #e6ffed !default;
$color-removal: #ffeef0 !default;

// Custom vars
$title-bar-bg: #ffffff;
$link-color: #2c2930;
$navbar-fg: #2c2930;
$navbar-fg-hover: #333333;
$navbar-bg: #f2f2f2;
$navbar-bg-hover: #e5e5e5;
