@import "variables";
@import url('https://fonts.googleapis.com/css?family=Archivo');

body {
  font-family: 'Archivo', sans-serif;
  letter-spacing: 0.5px;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Archivo", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.5px;
}

.show-more {
	text-align: left;
}
